/**
 * @fileoverview Creates the HTML root element and attaches the App component to it.
 */
import * as Sentry from "@sentry/react";
import "@splidejs/react-splide/css";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter, createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom";

import { App } from "./App";
import { ScrollToTop } from "./components/ScrollToTop";
import "./index.css";


const sentryClientKey = import.meta.env.VITE_SENTRY_DSN;
const isProd = import.meta.env.PROD;

// Only log sentry errors in staging and production
if (isProd) {
  Sentry.init({
    dsn: sentryClientKey,
    integrations: [Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),],
    replaysOnErrorSampleRate: 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: .1,
  });
}

ReactDOM.createRoot(document.querySelector("#root") as HTMLElement).render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
);
