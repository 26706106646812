import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import type { Database } from "../../../../types/supabase";
import { queryClient } from "../../../App";
import { supabase } from "../../../clients/supabaseClient";
import { useUserStore } from "../../../state/user";
import { QUERY_KEYS } from "../../queryKeys";

const TABLE = "user_to_notification";

export type UserNotification =
  | Database["public"]["Tables"]["user_to_notification"]["Row"] &
  Database["public"]["Tables"]["notification"]["Row"];

export const useLatestNotificationsQuery = (
  turnNotificationBellBerryOn: () => void
) => {
  const authUser = useUserStore((state) => state.user);
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: [QUERY_KEYS.notification, { userId: authUser?.id }],
    queryFn: () => fetchLatestNotifications(),
  });

  // subscribe to insert changes in the user_to_notification table
  useEffect(() => {
    const subscription = supabase
      .channel("table-filter-changes")
      .on(
        "postgres_changes",
        {
          event: "INSERT", schema: "public", table: "user_to_notification",
          filter: `user_id=eq.${authUser?.id}`
        },

        () => {
          refetch();
          turnNotificationBellBerryOn();
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [authUser, refetch]);

  return {
    isLoadingNotifications: isLoading,
    notifications: data,
    notificationsError: error,
    refetchNotifications: refetch,
  };
};

export function useUpdateNotificationToReadMutation() {
  const authUser = useUserStore((state) => state.user);
  return useMutation({
    mutationFn: async (notification_id: string) => {
      const response = await updateNotificationToRead(notification_id);
      return response.data?.[0];
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.notification, { userId: authUser?.id }],
      });
    },
  });
}

type Notification =
  Database["public"]["Tables"]["user_to_notification"]["Row"] & {
    notification: Database["public"]["Tables"]["notification"]["Row"];
  };
async function fetchLatestNotifications(): Promise<UserNotification[]> {
  const { data, error } = await supabase
    .from(TABLE)
    .select("*, notification(*)")
    .order("created_at", { ascending: false })
    .limit(20);
  if (error) {
    Sentry.captureException(error);
  }
  return data
    ?.filter(
      (user_to_notification) =>
        user_to_notification.notification?.delivery_time &&
        user_to_notification.notification?.delivery_time <=
          new Date().toISOString()
    )
    .map((user_to_notification) => ({
      ...user_to_notification.notification,
      ...user_to_notification,
      notification: null,
    })) as UserNotification[];
}

export async function updateNotificationToRead(notification_id: string) {
  const { data, error } = await supabase
    .from(TABLE)
    .update({ is_read: true })
    .eq("id", notification_id)
    .select();

  if (error) {
    Sentry.captureException(error);
  }
  return { data };
}
