import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import type { Video } from "../../../backend/resources/video/video";
import { supabase } from "../../../clients/supabaseClient";
import { useNavigateBack } from "../../../lib/routing";
import BackButton from "../../BackButton/BackButton";
import { PageContainer } from "../../PageContainer";
import { PageMainHeader } from "../../PageMainHeader";
import { VideoDisplay } from "../VideoDisplay";

export default function VideoCategoryPage() {
  const { category } = useParams();
  const navigateBack = useNavigateBack();

  const [videos, setVideos] = useState<Video[]>([]);

  async function fetchVideos() {
    if (!category) return;
    const { data, error } = await supabase
      .from("video_categorization")
      .select(
        "video!inner(id, slug, title, description, url, hide), content_category!inner(name)"
      )
      .eq("content_category.name", category)
      .eq("video.hide", false)
      .returns<{ video: Video }[]>();
    if (data) {
      setVideos(data.map((item) => item.video));
    }
  }

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <PageContainer> 
      <BackButton className="mb-2 " />
      <PageMainHeader text={category ?? ""} />

      <div className="flex flex-wrap items-center mt-4 max-w-full gap-3 justify-center md:justify-start">
        {videos?.map((video, i) => (
          <VideoDisplay
            uniqueId={`${`scrollableVideoContainer-${category}`}-${video.id}`}
            key={video.id}
            id={video.id}
            slug={video.slug}
            isFirst={i === 0}
            isLast={i === videos.length - 1}
            setShowLeftArrow={() => { }}
            setShowRightArrow={() => { }}
            src={video.url}
            title={video.title}
            description={video.description}
          />
        ))}
      </div>
    </PageContainer>
  );
}
