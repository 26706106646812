/**
 * @fileoverview Handles user authentication
 */
import { Session } from "@supabase/supabase-js";
import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../App";
import { createUser } from "../../backend/functions";
import { fetchUserById } from "../../backend/resources/user";
import { supabase } from "../../clients/supabaseClient";
import { SharedRoute, useAppNavigate } from "../../lib/routing";
import { useAppOnboardingStore } from "../../state/appOnboarding";
import { resetAssessmentStore } from "../../state/assessment";
import { useInvitationStore } from "../../state/invitation";
import { clearLocalStorageKeys } from "../../state/localStorageKeys";
import { useLogoutReasonStore } from "../../state/logout/logout";
import { useNetworkStore } from "../../state/network/network";
import { useOrganizationStore } from "../../state/organization/organization";
import { useUserStore } from "../../state/user";
import { resetUserUpdateStore } from "../../state/userUpdate/userUpdate";
import { LoadingSpinner } from "../LoadingSpinner";

interface Props {
  children: ReactNode;
}

/**
 * Auth component that renders on mount and
 * listens for auth state changes or active sessions.
 */
export function Auth({ children }: Props) {
  const navigate = useNavigate();
  const appNavigate = useAppNavigate();
  const [loading, setLoading] = useState(true);
  const isCreatingRef = useRef(false);
  const authUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const invitationId = useInvitationStore((state) => state.invitationId);
  const isForOrganization = useInvitationStore(
    (state) => state.isForOrganization
  );
  const resetInvitationStore = useInvitationStore(
    (state) => state.resetInvitationStore
  );
  const resetNetworkStore = useNetworkStore((state) => state.reset);
  const resetUserStore = useUserStore((state) => state.reset);
  const signOut = useUserStore((state) => state.signOut);
  const resetOrg = useOrganizationStore((state) => state.reset);
  const resetOnboarding = useAppOnboardingStore((state) => state.reset);
  const setLogoutStatus = useLogoutReasonStore((state) => state.setLogoutStatus);
  // useIntercom();

  function resetStores(redirectLink?: string) {
    resetUserStore();
    resetInvitationStore();
    resetAssessmentStore();
    resetUserUpdateStore();
    resetNetworkStore();
    resetOnboarding();
    resetOrg();
    queryClient.removeQueries();
    clearLocalStorageKeys();
    if (redirectLink) navigate(redirectLink);
  }

  async function signOutUserAndRedirect(redirectLink: string) {
    signOut();
    resetStores(redirectLink);
  }

  async function fetchAndSetUser(id: string, email: string | null | undefined, session: Session | null) {
    if (!email) {
      return;
    }


    let { data, error } = await fetchUserById(id);

    if (error) {
      const response = await createUser(invitationId, id, isForOrganization, session);
      if (response?.error) {
        signOutUserAndRedirect(
          invitationId ? `/invitation_invalid/${response.error}` : "/"
        );
        return;
      } else {
        ({ data, error } = await fetchUserById(id));
      }
    }

    if (data) {
      setUser({
        email,
        id,
        profile_image: data.profile_image,
        first_name: data.first_name,
        last_name: data.last_name,
        has_approved_tos: data.has_approved_tos,
        created_at: data.created_at,
        cell_number: data.cell_number,
        timezone: data.timezone,
        has_approved_sms_notifs: data.has_approved_sms_notifs
      });
    } else {
      signOutUserAndRedirect(`/`);
    }
  }

  async function checkForActiveSession() {
    /// 1. get supabase session from local storage, refreshing if needed
    /// https://supabase.com/docs/reference/javascript/auth-getsession
    const maybeActiveSession = (await supabase.auth.getSession()).data.session

    /// 2. if there was a session in local, fetch-set non-malicious users
    if (maybeActiveSession?.user) {
      const { id, email } = maybeActiveSession.user;
      /// if the supabase local storage user.id doesn't match the saved authUser.id, boot
      if (authUser && id !== authUser?.id) {
        signOutUserAndRedirect("/");
      } else {
        await fetchAndSetUser(id, email, maybeActiveSession);
      }
    }
    /// 3. else if there was not a session in local, reset all state
    else {
      resetStores()
    }

    setLoading(false);
  }

  async function updateSession(session?: Session) {
    /// 1. if the session from the listener is not updating the user object, do nothing
    if (session?.user?.id == authUser?.id) {
      // early exit
      return;
    }
    /// 2. else if there is a session and it is an update
    if (session && !isCreatingRef.current) {
      // note: without isCreating ref flag there's a race condition where authUser isn't set yet because
      // fetchAndSetUser is async — so if SIGNED_IN onAuthStateChange event triggers multiple times
      // and this function gets called multiple times it will reset an in-progress createUser call
      isCreatingRef.current = true;
      const { id, email } = session.user;
      await fetchAndSetUser(id, email, session)
      isCreatingRef.current = false;
    }
  }

  useEffect(() => {
    /// 1. checkForActiveSession() is called immediately when the component mounts to check the current session.
    checkForActiveSession();
    /// 2. Listen for changes on auth state (logged in, signed out, etc.)
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (session && (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED' || event === "PASSWORD_RECOVERY")) {
          setTimeout(async () => {
            updateSession(session)
          }, 0)
          if (event === "PASSWORD_RECOVERY") {
            appNavigate({ path: SharedRoute.RESET_PASSWORD })
          }
        }
        if (["USER_DELETED"].includes(event)) {
          signOutUserAndRedirect("/");
        } else if (event === "SIGNED_OUT") {
          setLogoutStatus("LOGGED_OUT")
          resetStores("/login");
        }
      }
    );

    return () => {
      listener.subscription.unsubscribe();
    };
  }, [invitationId]);


  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner className="w-8 h-8" />
      </div>
    )
  } else {
    return <>{children}</>;
  }
}
