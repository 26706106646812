import * as Sentry from '@sentry/react';
import { useMutation, useQuery } from "@tanstack/react-query";
import { Database } from "../../../../types/supabase";
import { queryClient } from "../../../App";
import { supabase } from "../../../clients/supabaseClient";
import { fetchSignedUrl } from '../../../hooks/useSignedUrl';
import { useUserStore } from "../../../state/user";
import { NotificationType, sendNotification } from '../../functions';
import { QUERY_KEYS } from "../../queryKeys";
import { User } from '../user';

export const TABLE = "conversation_document"
export type ConversationDocument = Database["public"]["Tables"]["conversation_document"]["Row"];

export const useConversationDocumentsByConversationId = (conversationId: string | undefined | null) => {
  return useQuery({
    queryKey: [QUERY_KEYS.conversation_document, { conversationId }],
    queryFn: () => fetchConversationDocumentsByConversationId(conversationId)
  });
}

export function useInsertConversationDocumentMutation() {
  const authUser = useUserStore((state) => state.user);
  return useMutation({
    mutationFn: async (variables: { file: File | undefined, conversationId: string | undefined | null, networkId: string, isExternal: boolean }) => {
      if (!authUser || !variables.conversationId || !variables.file) return
      // insert document into conversation_document table
      const documentId = await insertDocuments(variables.conversationId, variables.file.name, authUser?.id)
      if (!documentId) return
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.conversation_document,
        ]
      });
      // upload document to storage
      const filename = `${variables.networkId}/${variables.file.name}`;
      await supabase.storage
        .from("documents")
        .upload(filename, variables.file, {
          cacheControl: "3600",
          upsert: true,
        })

      if (variables.isExternal) {
        // send document to conversation
        await sendDocumentToConversation(
          variables.file.name, documentId, variables.conversationId, variables.networkId, authUser)
      }
      return documentId
    },
  });
}

export function useDeleteConversationDocumentMutation() {
  return useMutation({
    mutationFn: async (variables: { document_name: string, conversationId: string | undefined | null }) => {
      if (!variables.conversationId) return
      await deleteDocuments(variables.conversationId, [variables.document_name])
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.conversation_document,
        ]
      });
    },
  });
}


// Helper functions


async function deleteDocuments(
  conversationId: string,
  documentIds: string[]
) {
  if (!conversationId) return;
  const { error } = await supabase
    .from(TABLE)
    .delete()
    .in("document_file_name", documentIds)
    .eq("conversation_id", conversationId);

  supabase
    .storage
    .from('documents')
    .remove(documentIds);

  if (error) {
    Sentry.captureException(error);
  }
}

async function insertDocuments(
  conversationId: string,
  document_name: string,
  uploader_user_id: string
) {

  const { data, error } = await supabase.from(TABLE).insert({
    conversation_id: conversationId,
    document_file_name: document_name,
    uploader_user_id
  }).select("id").limit(1).maybeSingle();

  if (error) {
    Sentry.captureException(error);
  }
  return data?.id;
}



async function sendDocumentToConversation(document_name: string, documentId: string, conversation_id: string, network_id: string, authUser: User) {
  //  First get the presigned url for the document
  const TEN_YEARS_IN_SECONDS = 10 * 365 * 24 * 60 * 60;
  const signedUrl = await fetchSignedUrl(`${network_id}/${document_name}`, "documents", TEN_YEARS_IN_SECONDS)
  // Then create a message with the document
  if (!signedUrl) return;
  const newMessageContent = `${authUser.first_name} ${authUser.last_name} has sent you a document: ${document_name}. You can view the document by [clicking here](${signedUrl})`
  const { error, data: newMessage } = await supabase
    .from("chat_gpt_message")
    .insert({ chat_gpt_conversation_id: conversation_id, content: newMessageContent, role: "user", user_id: authUser.id })
    .select("id, chat_gpt_conversation(type, external_participant_id)")
    .limit(1)
    .maybeSingle()
  if (error) {
    Sentry.captureException(error);
  }
  // Send a notification to the external provider
  if (newMessage?.chat_gpt_conversation?.external_participant_id) {
    await sendNotification(documentId, NotificationType.EXTERNAL_DOCUMENT_SHARING)
  }

  return signedUrl
}


async function fetchConversationDocumentsByConversationId(conversationId: string | undefined | null) {
  if (!conversationId) return null
  const { data } = await supabase
    .from(TABLE)
    .select("*")
    .eq("conversation_id", conversationId);
  return data as ConversationDocument[];
}

