import create from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { TaskStatus } from "../../backend/resources/planEntry";
import { LocalStorageKeys } from "../localStorageKeys";

// types

export type MyPlanLayoutOption = "list" | "calendar";

export type MyPlanListSectionState = {
  isOpen: boolean;
};

export type MyPlanFiltersState = {
  status_filters: Record<TaskStatus, boolean>;
};

export const PlanEntrySortKeys = [
  "name",
  "scheduled_date_time",
  "who",
] as const; // so we can iterate the string literals type

export type PlanEntrySortKey = (typeof PlanEntrySortKeys)[number];

export const PlanEntryOrderKeys = ["ascending", "descending"] as const; // so we can iterate the string literals type
export type PlanEntryOrderKey = (typeof PlanEntryOrderKeys)[number]; // can move this up into shared

export type MyPlanSortsState = {
  activeSortKey: PlanEntrySortKey;
  activeSortOrder: PlanEntryOrderKey;
};

// store

type State = {
  activeMyPlanLayout: MyPlanLayoutOption;
  myPlanFiltersState: MyPlanFiltersState;
  myPlanSortsState: MyPlanSortsState;
  newEntryBookmarkId?: string | null;
};

type Actions = {
  setMyPlanLayout: (layoutOption: MyPlanLayoutOption) => void;
  setMyPlanFiltersState: (filtersState: MyPlanFiltersState) => void;
  setMyPlanSortsState: (sortsState: MyPlanSortsState) => void;
  setNewEntryBookmarkId: (bookmarkId: string | undefined | null) => void;
};

const initialState: State = {
  activeMyPlanLayout: "list",
  myPlanFiltersState: {
    status_filters: {
      done: true,
      not_started: false,
      in_progress: false,
      not_applicable: false,
    },
    // + time range filters
  },
  myPlanSortsState: {
    activeSortKey: "scheduled_date_time",
    activeSortOrder: "ascending",
  },
  newEntryBookmarkId: undefined,
};

export const useMyPlanStore = create<State & Actions>()(
  persist(
    immer((set, get) => ({
      /**
       * state
       */
      ...initialState,

      /**
       * actions
       */
      setMyPlanLayout: (layoutOption: MyPlanLayoutOption) =>
        set((state) => {
          state.activeMyPlanLayout = layoutOption;
        }),

      setMyPlanFiltersState: (filtersState) =>
        set((state) => {
          state.myPlanFiltersState = filtersState;
        }),

      setMyPlanSortsState: (sortsState) =>
        set((state) => {
          state.myPlanSortsState = sortsState;
        }),

      setNewEntryBookmarkId: (bookmarkId) =>
        set((state) => {
          state.newEntryBookmarkId = bookmarkId;
        }),
    })),
    {
      name: LocalStorageKeys.myPlan,
      getStorage: () => localStorage,
    }
  )
);

// notes on the create<T>()() 'currying' syntax
// https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#basic-usage
